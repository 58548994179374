import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { BracketInfo } from "../../types/custom_types";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Navbar from "./navbar";
import Footer from "./footer";
import SmallLoading from "./Loading/SmallLoading";

const BracketList = () => {
  const [brackets, setBrackets] = useState<BracketInfo[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBrackets = async () => {
      const response = await axios.get<BracketInfo[]>(
        `${process.env.REACT_APP_API_URL}/userapi/brackets/`
      );
      setBrackets(response.data);
      setLoading(false);
    };

    fetchBrackets();
  }, []);

  return (
    <div className="bracket-list">
      <Navbar />
      <h1 className="bracket-list-heading pacifico-regular">
        Welcome to the Tournament
      </h1>
      <h2 className="bracket-list-subheading">March Madness 2025</h2>
      <div className="tournament-rules-container">
        <h3>Tournament Rules</h3>
        <ul>
          <li>Entry closes when the first Round 1 game starts.</li>
          <li>The First Four games do not receive points.</li>
          <li>The highest total score wins.</li>
          <li>Point values are doubled every round.</li>
          <li>
            You may enter multiple brackets, but each incurs a $25 entry fee.
          </li>
          <li>Fees should be paid directly to the winner at the end.</li>
        </ul>
      </div>

      {/* <div className="create-bracket-link-container">
        <Link
          to="/create-bracket"
          className="create-bracket-link pacifico-regular"
        >
          → Build Your Bracket ←
        </Link>
      </div> */}

      <div className="bracket-link-container">
        <h1>Rankings</h1>
        {loading && (
          <div style={{ width: "200px", height: "200px", marginTop: "30px" }}>
            <SmallLoading />
          </div>
        )}
        {brackets.length === 0 && !loading && (
          <h1>No brackets have been created yet.</h1>
        )}
        {brackets.map((bracket, index) => (
          <Link
            className="bracket-link"
            key={bracket.id}
            to={`/bracket/${bracket.id}`}
          >
            <h2>
              #{index + 1} {bracket.name}
            </h2>
            <div className="bracket-link-bottom">
              <p style={{ marginRight: "5px" }}>{bracket.username}</p>
              <p>Score: {bracket.score} pts</p>
            </div>
          </Link>
        ))}
      </div>
      <div className="hall-of-fame-container">
        <h1>★ Hall of Fame ★</h1>
        <div className="hall-of-fame-entry-container">
          <div className="hall-entry">
            <h2>2022</h2>
            <FontAwesomeIcon icon={faTrophy} className="trophy" />
            <div className="hall-entry-bottom">
              <p className="pacifico-regular">Lori</p>
              <p>Score: 110 pts</p>
            </div>
          </div>
          <div className="hall-entry">
            <h2>2023</h2>
            <FontAwesomeIcon icon={faTrophy} className="trophy" />
            <div className="hall-entry-bottom">
              <p className="pacifico-regular">J. Dough-nuts</p>
              <p>Score: 135 pts</p>
            </div>
          </div>
          <div className="hall-entry">
            <h2>2024</h2>
            <FontAwesomeIcon icon={faTrophy} className="trophy" />
            <div className="hall-entry-bottom">
              <p className="pacifico-regular">Jan</p>
              <p>Score: 136 pts</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BracketList;
